import React from 'react';
import Quality from '../Components/Quality';

class QualityAndEnv extends React.Component{
    render(){
        return(

            <Quality />
        )
    }
}
export default QualityAndEnv;